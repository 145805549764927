import { ContextCollection } from "../../contexts/DependencyContext";
import { StrapiClient } from "./clients/StrapiClient";
import QueryString from "qs";

export class PostsService {
    private readonly url: string;

    constructor(private readonly apiClient: StrapiClient) {
        this.url = "posts";
    }

    async getPaginatedAsync(conditions: any[], pageNumber: number, pageSize: number): Promise<{ results: GatsbyTypes.strapi_Posts[]; totalCount: number }> {
        const results = await this.apiClient.get<GatsbyTypes.strapi_Posts[]>(
            `${this.url}?${QueryString.stringify({
                _where: conditions,
                _start: (pageNumber - 1) * pageSize,
                _limit: pageSize,
                _sort: "date:DESC",
            })}`
        );

        const totalCount = results.length > 0 ? await this.apiClient.get<number>(`${this.url}/count?${QueryString.stringify({ _where: conditions })}`) : 0;

        return { results, totalCount };
    }
}

ContextCollection.registerSingleton(PostsService, [StrapiClient]);
