import { ReactElement } from "react";
import { className } from "../../../shared/ClassName";
import { chevron } from "../../../components/Icons";
import * as styles from "./Pagination.module.scss";

interface IPaginationProps {
    pageSize: number;
    totalItems: number;
    currentPage: number;
    totalLinks: number;
    setPage: (page: number) => void;
    setPreviousPage: () => void;
    setNextPage: () => void;
}

function Pagination({ pageSize, totalItems, currentPage, totalLinks, setPage, setPreviousPage, setNextPage }: IPaginationProps): ReactElement {
    const pageNumbers = [];
    const first = 1;
    let previous = currentPage - 1;
    let next = currentPage + 1;
    const last = Math.ceil(totalItems / pageSize);

    if (previous < first) previous = first;
    if (next > last) next = last;

    const halfLinks = totalLinks / 2;
    let linkFrom = currentPage - halfLinks;
    let linkTo = currentPage + halfLinks;

    if (linkFrom < first) linkTo += first - linkFrom;
    if (linkTo > last) linkFrom += last - linkTo;
    if (linkFrom < first) linkFrom = first;
    if (linkTo > last) linkTo = last;

    for (let i = linkFrom; i <= linkTo; i++) {
        pageNumbers.push(i);
    }
    const disableMin = currentPage == 1;
    const disableMax = currentPage == last;
    return (
        <div className={styles.paginationContainer}>
            <nav>
                <ul className={styles.pagination}>
                    {!disableMin && (
                        <li>
                            <button onClick={() => setPage(first)}>
                                <span className="material-icons">
                                    {chevron.left}
                                    {chevron.left}
                                </span>
                            </button>
                        </li>
                    )}
                    {!disableMin && (
                        <li>
                            <button onClick={() => setPreviousPage()}>
                                <span className="material-icons"> {chevron.left} </span>
                            </button>
                        </li>
                    )}
                    {pageNumbers.map(number => (
                        <li key={number}>
                            <button {...className({ [styles.selectedButton]: currentPage === number })} onClick={() => setPage(number)}>
                                {number}
                            </button>
                        </li>
                    ))}
                    {!disableMax && (
                        <li>
                            <button onClick={() => setNextPage()}>
                                <span className="material-icons"> {chevron.right} </span>
                            </button>
                        </li>
                    )}
                    {!disableMax && (
                        <li>
                            <button onClick={() => setPage(last)}>
                                <span className="material-icons">
                                    {chevron.right}
                                    {chevron.right}
                                </span>
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default Pagination;
