import { ReactElement } from "react";
import { useDependency } from "../../../contexts/DependencyContext";
import { NavigationService } from "../../../services/NavigationService";
import * as styles from "./SecondaryPost.module.scss";

export default function SecondaryPost(props: GatsbyTypes.strapi_Posts): ReactElement {
    const postCategory = props.post_categories && props.post_categories.length > 0 ? props.post_categories[0] : null;
    const navigationService = useDependency(NavigationService);

    async function navigateToPostAsync(): Promise<void> {
        await navigationService.navigateAsync(`/blog/${props.id}`);
    }

    return (
        <div className={styles.post} onClick={navigateToPostAsync}>
            <div className={styles.postCategory}>
                {postCategory && <span style={{ color: postCategory.color }}>{postCategory.name}</span>}
                {props.products?.map(x => (
                    <span key={x?.id} style={{ color: x?.color }}>
                        - {x?.name}
                    </span>
                ))}
            </div>
            <h3>{props.title}</h3>
            {props.summary && <div className={styles.postSummary}>{props.summary}</div>}
        </div>
    );
}
