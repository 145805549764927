import { ReactElement, useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import useWindowWidth from "../../hooks/UseWindowWidth";
import { className } from "../../shared/ClassName";
import { PostsService } from "../../services/http/PostsService";
import { TelemetryService } from "../../services/telemetry/TelemetryService";
import Helmet from "../../components/Helmet";
import SecondaryPost from "../../components/Blog/SecondaryPost";
import Post from "../../components/Shared/Post";
import Filters, { FilterModel } from "../../components/Shared/Filters";
import { FilterItemModel } from "../../components/Shared/Filters/FilterItem";
import Pagination from "../../components/Shared/Pagination";
import Loader from "../../components/Shared/Loader";
import { headerImage, productIcon } from "../../components/Icons";
import * as styles from "./Blog.module.scss";

interface IBlogProps {
    data: GatsbyTypes.BlogPageQuery;
}

export default function BlogPage({ data }: IBlogProps): ReactElement {
    const [searching, setSearching] = useState(false);
    const [filteredPosts, setFilteredPosts] = useState(data.strapi.posts);
    const [filteredPostsCount, setFilteredPostsCount] = useState(-1);
    const [conditions, setConditions] = useState<any[] | undefined>();
    const [currentPage, setCurrentPage] = useState(1);
    const [filterModels, setFilterModels] = useState<FilterModel[]>([]);
    const postsService = useDependency(PostsService);
    const telemetryService = useDependency(TelemetryService);
    const isDesktop = useWindowWidth(769);
    const pageSize = data.strapi.blogPage?.pageSize ?? 8;

    useEffect(() => {
        const postCategoriesFilters = (data.strapi?.postCategories as GatsbyTypes.strapi_PostCategory[]).map(x => new FilterItemModel(x.id, x.name, x.iconSvg));
        const productsFilters = (data.strapi?.products as GatsbyTypes.strapi_Product[]).map(x => new FilterItemModel(x.id, x.name, productIcon));

        setFilterModels([
            new FilterModel("post_categories", data.strapi.blogPage?.postCategoriesFilterLabel, postCategoriesFilters, "category"),
            new FilterModel("products", data.strapi.blogPage?.productsFilterLabel, productsFilters, "product"),
        ]);
    }, []);

    useEffect(() => {
        if (!conditions) return;

        setSearching(true);

        const execute = async () => {
            try {
                window?.scrollTo(0, 0);
                const { results, totalCount } = await postsService.getPaginatedAsync(conditions, currentPage, pageSize);
                setFilteredPosts(results);
                setFilteredPostsCount(totalCount);
            } catch (error) {
                console.error(error);
            } finally {
                setSearching(false);
            }
        };

        execute();
    }, [conditions, currentPage]);

    function handleFiltersChange(searchText: string, filters: Map<string, FilterItemModel[]>) {
        const newConditions: any[] = [];
        if (searchText !== "") newConditions.push({ _or: [{ title_contains: searchText }, { summary_contains: searchText }] });
        filters.forEach((values, key) => newConditions.push({ [`${key}.id`]: values.map(x => x.id) }));
        setConditions(newConditions);
        if (currentPage > 1) setCurrentPage(1);
    }

    const highlightedPosts = currentPage === 1 && (conditions?.length ?? 0) === 0 ? filteredPosts?.slice(0, 5) ?? [] : [];
    const olderPosts = filteredPosts?.filter(x => !highlightedPosts.some(y => y?.id === x?.id)) ?? [];

    return (
        <>
            <Helmet title={"Blog"} description={""} />

            <section className={styles.blog}>
                <div className="headerContainer">
                    <div className="mainBox">
                        <h1>{data.strapi.blogPage?.base?.heading}</h1>
                    </div>
                    <div className="headerImage">{headerImage}</div>
                </div>

                <div className="mainBox">
                    <div className={styles.inner}>
                        <Filters
                            content={data.strapi.blogPage?.filters as GatsbyTypes.strapi_ComponentSharedFiltersContent}
                            filterModels={filterModels}
                            onChange={handleFiltersChange}
                            onFilterChange={(filterId, value) => telemetryService.blogSearch({ [filterId]: value })}
                        />

                        {searching ? (
                            <div className={styles.blogPosts}>
                                <Loader loadingText={data.strapi.blogPage?.filters?.loadingText} />
                            </div>
                        ) : (
                            <div className={styles.blogPosts}>
                                {filteredPosts && filteredPosts.length > 0 ? (
                                    <>
                                        {highlightedPosts.length > 0 && (
                                            <div {...className(styles.highlightedPosts, highlightedPosts.length >= 5 ? styles.includeGridPattern : "")}>
                                                {highlightedPosts.map(x => (
                                                    <Post key={x?.id} post={x as GatsbyTypes.strapi_Posts} linkText={data.strapi.blogPage?.postLinkText ?? ""} />
                                                ))}
                                            </div>
                                        )}

                                        {olderPosts.map((x: any) => (
                                            <SecondaryPost key={x?.id} {...(x as GatsbyTypes.strapi_Posts)} />
                                        ))}

                                        {filteredPostsCount < 0 ? (
                                            <div className={styles.showMoreButtonContainer}>
                                                <button className={styles.showMoreButton} onClick={() => handleFiltersChange("", new Map<string, FilterItemModel[]>())}>
                                                    {data.strapi.blogPage?.btnShowMoreText ?? ""}
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                {filteredPostsCount > pageSize && (
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        pageSize={pageSize}
                                                        totalItems={filteredPostsCount}
                                                        totalLinks={isDesktop ? 6 : 2}
                                                        setPage={setCurrentPage}
                                                        setPreviousPage={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                                                        setNextPage={() => setCurrentPage(currentPage === Math.ceil(filteredPostsCount / pageSize) ? currentPage : currentPage + 1)}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div className={styles.noPosts}>{data.strapi.blogPage?.filters?.noDataText ?? ""}</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export const query = graphql`
    query BlogPage {
        strapi {
            blogPage {
                base {
                    heading
                }
                filters {
                    headingText
                    searchLabel
                    applyButtonText
                    loadingText
                    noDataText
                }
                postLinkText
                productLinkText
                postCategoriesFilterLabel
                productsFilterLabel
                btnShowMoreText
                pageSize
            }
            posts(limit: 8, sort: "date:desc,updated_at:desc") {
                id
                date
                title
                summary
                post_categories {
                    id
                    name
                    color
                }
                products {
                    id
                    name
                    color
                }
            }
            postCategories {
                id
                name
                iconSvg
            }
            products {
                id
                name
            }
        }
    }
`;
